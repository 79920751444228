import { memo } from 'react';

import { capitalizeFirstLetter } from '@/utility/stringHelper';

export type NPLInputBottomSectionProps = {
  error?: string;
  maxLength?: number;
  supportingText?: string;
  tailSupportingText?: string;
  valueLength?: number;
};

const NPLInputBottomSection = ({
  supportingText,
  tailSupportingText,
  error,
  maxLength,
  valueLength = 0
}: NPLInputBottomSectionProps) => {
  if (!tailSupportingText && !supportingText && !error && !maxLength) {
    return null;
  }

  return (
    <div className="flex justify-between text-body-sm text-npl-text-icon-on-light-surface-secondary">
      <div>
        {supportingText && (
          <div className="text-npl-text-icon-on-light-surface-tertiary text-body-sm">
            {supportingText}
          </div>
        )}
        {error && (
          <div className="text-npl-text-error-on-light text-body-sm">
            {capitalizeFirstLetter({ string: error })}
          </div>
        )}
      </div>
      <div>
        {tailSupportingText && (
          <div className="flex justify-end flex-grow font-medium text-label-sm text-npl-text-tertiary-on-light">
            {tailSupportingText}
          </div>
        )}
        {maxLength && (
          <div className="flex justify-end flex-grow">
            {valueLength}/{maxLength}
          </div>
        )}
      </div>
    </div>
  );
};

// memoize because it's a presentational component
export default memo(NPLInputBottomSection);
